var productName = 'schedulerpro';import GridFeatureManager from '../../Grid/feature/GridFeatureManager.js';
import SchedulerEventResize from '../../Scheduler/feature/EventResize.js';
/**
 * @module SchedulerPro/feature/EventResize
 */
/**
 * Feature that allows resizing an event by dragging its end.
 *
 * By default it displays a tooltip with the new start and end dates, formatted using
 * {@link Scheduler.view.mixin.TimelineViewPresets#config-displayDateFormat}.
 *
 * This feature is **enabled** by default
 *
 * @inlineexample SchedulerPro/feature/EventResize.js
 * @extends Scheduler/feature/EventResize
 * @typings Scheduler/feature/EventResize -> Scheduler/feature/SchedulerEventResize
 * @classtype eventResize
 * @feature
 */
export default class EventResize extends SchedulerEventResize {
    static get $name() {
        return 'EventResize';
    }
}
EventResize._$name = 'EventResize'; GridFeatureManager.registerFeature(EventResize, true, 'SchedulerPro');
