var productName = 'schedulerpro';var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Base, Mixin } from "../../../../ChronoGraph/class/BetterMixin.js";
import { calculate, Entity, field } from "../../../../ChronoGraph/replica/Entity.js";
import { CalendarIntervalMixin } from "../../../calendar/CalendarIntervalMixin.js";
import { CalendarIntervalStore } from "../../../calendar/CalendarIntervalStore.js";
import { model_field } from "../../../chrono/ModelFieldAtom.js";
import { BaseCalendarMixin } from "../scheduler_basic/BaseCalendarMixin.js";
import { BaseResourceMixin } from "../scheduler_basic/BaseResourceMixin.js";
export class ResourceAllocationEventRangeCalendarIntervalMixin extends CalendarIntervalMixin {
    // @model_field({ type : 'boolean', defaultValue : true })
    // isWorking : boolean
    // Calendar classes not entering graph, thus not using @model_field
    static get fields() {
        return [
            { name: 'isWorking', type: 'boolean', defaultValue: true }
        ];
    }
}
export class ResourceAllocationEventRangeCalendarIntervalStore extends CalendarIntervalStore {
    static get defaultConfig() {
        return {
            modelClass: ResourceAllocationEventRangeCalendarIntervalMixin
        };
    }
}
export class ResourceAllocationEventRangeCalendar extends BaseCalendarMixin {
    get intervalStoreClass() {
        return ResourceAllocationEventRangeCalendarIntervalStore;
    }
}
__decorate([
    model_field({ type: 'boolean', defaultValue: false })
], ResourceAllocationEventRangeCalendar.prototype, "unspecifiedTimeIsWorking", void 0);
/**
 * Resource allocation information for a certain tick.
 */
export class ResourceAllocationInterval extends Base {
    constructor() {
        super(...arguments);
        /**
         * Resource assignments ingoing in the [[tick|interval]].
         */
        this.assignments = null;
        /**
         * Resource effort in the [[tick|interval]] in milliseconds.
         */
        this.effort = 0;
        /**
         * Maximum possible resource effort in the [[tick|interval]] in milliseconds.
         */
        this.maxEffort = 0;
        /**
         * Resource utilization level in percent.
         */
        this.units = 0;
        /**
         * Indicates that the resource is over-allocated in the [[tick|interval]].
         * So `true` when [[effort]] is more than [[maxEffort|possible maximum]].
         */
        this.isOverallocated = false;
        /**
         * Indicates that the resource is under-allocated in the [[tick|interval]].
         * So `true` when [[effort]] is less than [[maxEffort|possible maximum]].
         */
        this.isUnderallocated = false;
    }
}
/**
 * Class implementing _resource allocation report_ - a data representing the provided [[resource]]
 * utilization in the provided period of time.
 * The data is grouped by the provided [[ticks|time intervals]]
 */
export class ResourceAllocationInfo extends Entity.mix(Base) {
    initialize(props) {
        props = Object.assign({ includeInactiveEvents: false }, props);
        super.initialize(props);
    }
    *shouldIncludeAssignmentInAllocation(assignment) {
        const event = yield assignment.$.event, units = yield assignment.$.units, includeInactiveEvents = yield this.$.includeInactiveEvents, inactive = event && (yield event.$.inactive), // includeInactiveEvents
        startDate = event && (yield event.$.startDate), endDate = event && (yield event.$.endDate);
        return Boolean(event && units && startDate && endDate && (includeInactiveEvents || !inactive));
    }
    *calculateAllocation() {
        const result = [], ticksCalendar = yield this.ticks, //$.ticks,
        resource = yield this.$.resource, includeInactiveEvents = yield this.$.includeInactiveEvents, assignments = yield resource.$.assigned, assignmentsByCalendar = new Map(), eventRanges = [];
        // collect the resource assignments into assignmentsByCalendar map
        for (const assignment of assignments) {
            // skip missing or unscheduled event assignments
            if (!(yield* this.shouldIncludeAssignmentInAllocation(assignment)))
                continue;
            // we're going to need up-to-date assignment "units" below in this method ..so we yield it here
            yield assignment.$.units;
            const event = yield assignment.$.event, startDate = yield event.$.startDate, endDate = yield event.$.endDate;
            eventRanges.push({ startDate, endDate, assignment });
            const eventCalendar = yield event.$.effectiveCalendar;
            let assignments = assignmentsByCalendar.get(eventCalendar);
            if (!assignments) {
                assignments = [];
                assignmentsByCalendar.set(eventCalendar, assignments);
            }
            assignments.push(assignment);
        }
        const eventRangesCalendar = new ResourceAllocationEventRangeCalendar({ intervals: eventRanges });
        // Provide extra calendars:
        // 1) a calendar containing list of ticks to group the resource allocation by
        // 2) a calendar containing list of assigned event start/end ranges
        // 3) assigned task calendars
        const calendars = [ticksCalendar, eventRangesCalendar, ...assignmentsByCalendar.keys()];
        const ticksData = new Map();
        // Initialize the resulting array with empty items
        ticksCalendar.intervalStore.forEach(tick => {
            const tickData = ResourceAllocationInterval.new({ tick, resource });
            ticksData.set(tick, tickData);
            result.push(tickData);
        });
        let weightedUnitsSum, weightsSum;
        yield* resource.forEachAvailabilityInterval({
            startDate: result[0].tick.startDate,
            endDate: result[result.length - 1].tick.endDate,
            calendars
        }, (intervalStartDate, intervalEndDate, intervalData) => {
            const isWorkingCalendar = intervalData.getCalendarsWorkStatus();
            // We are inside a tick interval and it's a working time according
            // to a resource calendar
            if (isWorkingCalendar.get(ticksCalendar)) {
                const tick = intervalData.intervalsByCalendar.get(ticksCalendar)[0], intervalDuration = intervalEndDate.getTime() - intervalStartDate.getTime(), tickData = ticksData.get(tick), tickAssignments = tickData.assignments || new Set();
                if (!tickData.assignments) {
                    weightedUnitsSum = 0;
                    weightsSum = 0;
                }
                let units = 0, duration;
                intervalData.intervalsByCalendar.get(eventRangesCalendar).forEach((interval) => {
                    const assignment = interval.assignment;
                    // TODO:
                    // We don't do yield "assignment.event.*" expressions since we did it previously
                    // while looping the assignments because we cannot yield from the iterator callback
                    if (assignment && isWorkingCalendar.get(assignment.event.effectiveCalendar)) {
                        // constrain the event start/end with the tick borders
                        const workingStartDate = Math.max(intervalStartDate.getTime(), assignment.event.startDate.getTime());
                        const workingEndDate = Math.min(intervalEndDate.getTime(), assignment.event.endDate.getTime());
                        duration = workingEndDate - workingStartDate;
                        tickData.effort += duration * assignment.units / 100;
                        // collect total resource usage percent in the current interval
                        units += assignment.units;
                        tickAssignments.add(assignment);
                    }
                });
                tickData.maxEffort += intervalDuration;
                // if we have assignments running in the interval - calculate average allocation %
                if (units) {
                    if (duration) {
                        // keep weightedUnitsSum & weightsSum since there might be another intervals in the tick
                        weightedUnitsSum += duration * units;
                        weightsSum += duration;
                        // "units" weighted arithmetic mean w/ duration values as weights
                        tickData.units = weightedUnitsSum / weightsSum;
                    }
                    else if (!weightedUnitsSum) {
                        tickData.units = units;
                    }
                }
                if (tickAssignments.size) {
                    tickData.assignments = tickAssignments;
                    tickData.isOverallocated = tickData.isOverallocated || units > 100;
                    tickData.isUnderallocated = tickData.isUnderallocated || units < 100;
                }
            }
        });
        return result;
    }
}
__decorate([
    field()
], ResourceAllocationInfo.prototype, "resource", void 0);
__decorate([
    field()
], ResourceAllocationInfo.prototype, "includeInactiveEvents", void 0);
__decorate([
    field()
], ResourceAllocationInfo.prototype, "allocation", void 0);
__decorate([
    calculate('allocation')
], ResourceAllocationInfo.prototype, "calculateAllocation", null);
/**
 * A mixin for the resource entity at the Scheduler Pro level.
 */
export class SchedulerProResourceMixin extends Mixin([BaseResourceMixin], (base) => {
    const superProto = base.prototype;
    class SchedulerProResourceMixin extends base {
        constructor() {
            super(...arguments);
            this.observers = new Set();
            this.entities = new Set();
        }
        leaveGraph(replica) {
            const { graph } = this;
            for (const entity of this.entities) {
                graph.removeEntity(entity);
            }
            for (const observer of this.observers) {
                graph.removeIdentifier(observer);
            }
            superProto.leaveGraph.call(this, replica);
        }
        *forEachAvailabilityInterval(options, func) {
            const calendar = yield this.$.effectiveCalendar;
            const effectiveCalendarsCombination = this.getProject().combineCalendars([calendar].concat(options.calendars || []));
            return effectiveCalendarsCombination.forEachAvailabilityInterval(options, (startDate, endDate, calendarCacheIntervalMultiple) => {
                const calendarsStatus = calendarCacheIntervalMultiple.getCalendarsWorkStatus();
                if (calendarsStatus.get(calendar)) {
                    return func(startDate, endDate, calendarCacheIntervalMultiple);
                }
            });
        }
    }
    return SchedulerProResourceMixin;
}) {
}
