var productName = 'schedulerpro';import { Mixin } from "../../ChronoGraph/class/BetterMixin.js";
import { AbstractPartOfProjectGenericMixin } from "./AbstractPartOfProjectGenericMixin.js";
/**
 * This a base generic mixin for every class, that belongs to a scheduler_core project.
 *
 * It just provides getter/setter for the `project` property, along with some convenience methods
 * to access the project's stores.
 */
export class CorePartOfProjectGenericMixin extends Mixin([AbstractPartOfProjectGenericMixin], (base) => {
    const superProto = base.prototype;
    class CorePartOfProjectGenericMixin extends base {
        //region Store getters
        get eventStore() {
            var _a;
            return (_a = this.project) === null || _a === void 0 ? void 0 : _a.eventStore;
        }
        get resourceStore() {
            var _a;
            return (_a = this.project) === null || _a === void 0 ? void 0 : _a.resourceStore;
        }
        get assignmentStore() {
            var _a;
            return (_a = this.project) === null || _a === void 0 ? void 0 : _a.assignmentStore;
        }
        get dependencyStore() {
            var _a;
            return (_a = this.project) === null || _a === void 0 ? void 0 : _a.dependencyStore;
        }
        get calendarManagerStore() {
            var _a;
            return (_a = this.project) === null || _a === void 0 ? void 0 : _a.calendarManagerStore;
        }
        //endregion
        //region Entity getters
        /**
         * Convenience method to get the instance of event by its id.
         */
        getEventById(id) {
            var _a;
            return (_a = this.eventStore) === null || _a === void 0 ? void 0 : _a.getById(id);
        }
        /**
         * Convenience method to get the instance of dependency by its id.
         */
        getDependencyById(id) {
            var _a;
            return (_a = this.dependencyStore) === null || _a === void 0 ? void 0 : _a.getById(id);
        }
        /**
         * Convenience method to get the instance of resource by its id.
         */
        getResourceById(id) {
            var _a;
            return (_a = this.resourceStore) === null || _a === void 0 ? void 0 : _a.getById(id);
        }
        /**
         * Convenience method to get the instance of assignment by its id.
         */
        getAssignmentById(id) {
            var _a;
            return (_a = this.assignmentStore) === null || _a === void 0 ? void 0 : _a.getById(id);
        }
        /**
         * Convenience method to get the instance of calendar by its id.
         */
        getCalendarById(id) {
            var _a;
            return (_a = this.calendarManagerStore) === null || _a === void 0 ? void 0 : _a.getById(id);
        }
    }
    return CorePartOfProjectGenericMixin;
}) {
}
